<template>
  <div class="event-page">
    <div class="event-page__logo">
      <img
        src="@/assets/img/logo.png"
        alt="Logo"
      >
    </div>
    <hgroup class="event-page__hero">
      <h1>God Bull Live II</h1>
      <p>
        Get ready for an adrenaline-fueled adventure and a high-class dining
        experience in Vegas!
      </p>
    </hgroup>
    <ConnectWallet v-if="!isConnected" />

    <div
      v-else
      class="event-page__action"
    >
      <h3>
        Tickets
        <br>
        <span v-if="ticketState.loading">Loading...</span>
        <span v-else>
          <span v-if="ticketState.ticketsLeft > 0">
            {{ ticketState.ticketsLeft }}
            left
          </span>
          <span v-else> (Sold Out)</span>
        </span>
      </h3>
      <div class="event-page__controllers">
        <button
          type="button"
          class="event-page__btn"
          :disabled="ticketState.amount <= 1"
          @click="ticketsController('sub')"
        >
          -
        </button>
        <input
          v-model="ticketState.amount"
          placeholder="Number of Tickets"
          class="event-page__controllers__input"
          type="number"
          min="1"
          max="10"
        >
        <button
          type="button"
          class="event-page__btn"
          :disabled="
            ticketState.amount >= ticketState.ticketsLeft ||
              ticketState.amount >= 4 - ticketState.bought
          "
          @click="ticketsController('add')"
        >
          +
        </button>
      </div>
      <button
        :disabled="
          isMintDisabled || ticketState.amount > 4 - ticketState.bought
        "
        style="width: 100%"
        class="event-page__btn"
        @click="mint"
      >
        <span v-if="mintState.loading || ticketState.loading">Loading...</span>
        <span v-else-if="ticketState.bought == 4">
          Max 4 tickets purchased
        </span>
        <span v-else>
          Mint {{ ticketState.amount }} Ticket{{
            ticketState.amount > 1 ? "s" : ""
          }}
          <br>
          ({{ mintPrice }})
        </span>
      </button>
      <p v-if="ticketState.bought < 4">
        You Have {{ ticketState.bought }} Tickets
      </p>
    </div>

    <h2 class="event-page__footer">
      LAS VEGAS, May 18-19 2023
    </h2>

    <h2 class="event-page__title event-page__title--large">
      FAQs
    </h2>
    <div class="event-page__faqs">
      <button
        v-for="faq in faqs"
        :key="faq.question"
        class="event-page__faqs__item"
      >
        <h3 class="event-page__faqs__item__question">
          {{ faq.question }}
        </h3>
        <p
          class="event-page__faqs__item__answer"
          v-html="faq.answer"
        />
      </button>
    </div>
  </div>
</template>

<script setup>
import { useApesStorageApi } from "@/api/useApesStorageApi";
import ConnectWallet from "@/components/BOTB/ConnectWallet.vue";
import {
  buyTicket,
  getTicketsTotalSupply,
  getWalletPurchases,
} from "@/functions/events";
import { useWeb3Store } from "@/store/web3";
import { computed, ref, watch } from "vue";

const mintState = ref({
  loading: false,
  error: null,
});
const ticketState = ref({
  weiPrice: null,
  ethPrice: null,
  ticketsLeft: 0,
  maxTickets: 8,
  amount: 1,
  loading: true,
  error: null,
  bought: 0,
});

// get ticket price from API
const getTicketsInfo = async () => {
  try {
    const res = await useApesStorageApi({
      throw: true,
      toast: { error: true },
    }).exec({
      url: "/tickets/wallet-price",
      params: {
        wallet: useWeb3Store().walletAddress,
      },
    });

    ticketState.value.weiPrice = res.price;
    ticketState.value.ethPrice = res.priceInETH;
    // TODO: update bought tickets state from API
    const boughtTickets = await getWalletPurchases({ eventId: 3 });
    ticketState.value.bought = boughtTickets;
  } catch (error) {
    console.error(error);
    ticketState.value.error = error.message;
  }
};

const mintPrice = computed(() => {
  if (!ticketState.value.amount) return "";
  return ticketState.value.amount * 1 + " ETH";
  // if (!ticketState.value.ethPrice) return "";
  // return (
  //   (ticketState.value.ethPrice * ticketState.value.amount)
  //     .toString()
  //     .slice(0, 4) + " ETH"
  // );
});

// check is Wallet Connected
const isConnected = computed(() => {
  return useWeb3Store().isConnected;
});
const chainId = computed(() => {
  return useWeb3Store().network.chainId;
});

const ticketsController = (type) => {
  if (type === "add") {
    if (
      ticketState.value.amount < ticketState.value.ticketsLeft &&
      ticketState.value.amount < 4 - ticketState.value.bought
    )
      ticketState.value.amount++;
  } else {
    if (ticketState.value.amount > 1) ticketState.value.amount--;
  }
};

const isMintDisabled = computed(() => {
  return (
    ticketState.value.amount > ticketState.value.ticketsLeft ||
    ticketState.value.amount < 1 ||
    ticketState.value.ethPrice === null ||
    mintPrice.value <= 0 ||
    mintState.value.loading ||
    ticketState.value.loading
  );
});

// add mint function
const mint = async () => {
  if (isMintDisabled.value) return;
  mintState.value.loading = true;
  await buyTicket({
    amount: ticketState.value.amount,
    eventId: 3,
  });
  mintState.value.loading = false;
  await getTotalSupply();
  await getTicketsInfo();
};

// get total supply of tickets
const getTotalSupply = async () => {
  try {
    const totalSupply = await getTicketsTotalSupply({
      eventId: 3,
    });
    ticketState.value.ticketsLeft = ticketState.value.maxTickets - +totalSupply;
  } catch (error) {
    console.error(error);
  }
};

watch([isConnected, chainId], () => {
  if (isConnected.value && typeof chainId.value === "number") {
    Promise.all([getTicketsInfo(), getTotalSupply()])
      .then(() => {
        ticketState.value.loading = false;
      })
      .catch((error) => {
        console.error(error);
        ticketState.value.loading = false;
        ticketState.value.error = error.message;
      });
  }
});

const faqs = [
  {
    question: "What is the price?",
    answer: "1 ETH",
  },
  // {
  //   question: "How long will the auction run?",
  //   answer: "10 hours - 12pm CST to 10pm CST April 26th",
  // },
  {
    question: "Who can mint tickets?",
    answer: "Anyone who holds a God Bull",
  },
  {
    question: "How many tickets can be purchased per wallet?",
    answer: "As many as is available",
  },
  // {
  //   question: "When does the pricing change?",
  //   answer:
  //     "Price will drop every hour by 0.1 ETH each hour after the auction begins",
  // },
  {
    question: "When do I pay?",
    answer:
      "ETH will be transferred as soon as the tranaction is made. You will receive the NFT immediately.",
  },
  {
    question: "Where can I find the collection?",
    answer:
      "<a href='https://opensea.io/collection/bapirl' target='_blank'>https://opensea.io/collection/bapirl</a>",
  },

  // {
  //  question: "Can I reverse/change my bid?",
  //  answer:
  //    "Sorry no. All bids are final as they are written into the contract. You will have a chance of paying less than your bid as the top 8 bidders will pay the 8th lowest bid",
  //},

  {
    question: "Can I sell my ticket if I can’t make it?",
    answer:
      "Yes, of course. It’s an ERC-1155 token so you can sell it on secondary NFT markets such as OpenSea",
  },
];
</script>

<style lang="scss">
@import "@/assets/scss/mega-meetup.scss";
</style>
